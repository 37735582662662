<template>
  <main class="page__content--wider">
    <div class="page__head">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__text">
          <h1 class="page__head2__title">{{ viewName || id }}</h1>
        </div>
        <!-- <div class="page__head__actions">
          <div class="d-flex" style="display:flex"> -->
            <div class="hid-box" style="display:flex">
              <!-- <button
                type="button"
                class="
                  w-40
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                style="width:185px"
                @click="toggleFilterModal"
              >
                <span class="d-inline-flex mr-2">filter</span>
              </button> -->

              <div class="align-self-center mr-5 mx-2 dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  export
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    href="javascript:void(0)"
                    v-if="userCanExportToPDF"
                    @click="pdfDownload"
                    class="dropdown-item text--capital"
                    >PDF</a
                  >
                  <a
                    href="javascript:void(0)"
                    v-if="userCanExportToExcel"
                    @click="processDownload"
                    class="dropdown-item text--capital"
                  >
                    excel
                  </a>
                  <!-- <download-excel
                    class="dropdown-item text--capital"
                    style="cursor: pointer"
                    :data="download"
                    :fields="json_fields"
                    name="customView.xls"
                  >
                    excel
                  </download-excel> -->
                </div>
              </div>
            </div>
          <!-- </div>
        </div> -->
      </div>
    </div>
    <div class="page__body">
      <table class="customtable w-100" v-if="viewData">
        <thead v-if="viewData.items != undefined && viewData.items.length != 0">
          <tr>
            <th
              v-for="(head, j) in Object.keys(viewData.items[0])"
              :key="j"
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              {{ Object.keys(viewData.items[0])[j].replace(/_/g, " ") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="viewData.items != undefined && viewData.items.length != 0"
        >
          <tr v-for="(account, i) in viewData.items" :key="i">
            <td v-for="(col, j) in Object.keys(viewData.items[i])" :key="j">
              <span 
                v-if="Object.keys(viewData.items[i])[j].replace(/_/g, ' ').includes('date')"
              >
                {{ viewData.items[i][Object.keys(viewData.items[i])[j]] | moment }}
              </span>
              <span 
                v-else-if="Object.keys(viewData.items[i])[j].replace(/_/g, ' ').includes('loan name')"
                :class="getColoredText()"
                class="
                  table__body__text table__text
                  text--capital text--primary
                  cursor-pointer
                "
                @click="viewDetails(viewData.items[i]['loan_account_key'])"
              >
                {{ viewData.items[i][Object.keys(viewData.items[i])[j]] }}
              </span>
              <span v-else>{{ viewData.items[i][Object.keys(viewData.items[i])[j]] }}</span>

              <!-- {{ isDate(Object.keys(viewData.items[0])[j]) ? getHumanDate(viewData.items[i][Object.keys(viewData.items[i])[j]]) : viewData.items[i][Object.keys(viewData.items[i])[j]] }} -->
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination" v-if="viewData.items && !loading">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary">{{
              viewData ? viewData.pageIndex : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              viewData ? viewData.totalPages : 0
            }}</span>
            of
            <span>{{ viewData ? viewData.totalCount : 0 }}</span>
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="viewData"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!viewData.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>

            <div v-if="!isLargeSizePaged" class="d-flex align-items-center pagination__buttons">
              <button
                v-for="page in viewData.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': viewData.pageIndex === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
            </div>
            <div v-else class="text--capital text--regular pagination__text">
              Page
              <span class="d-inline-block text--primary">{{
                viewData.currentPage
              }}</span>
              of
              <span class="d-inline-block text--primary">{{
                viewData.totalPages
              }}</span>
              from <span class="d-inline-block text--primary">{{ viewData.totalCount }}</span> Records
            </div>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!viewData.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_VIEW_DATA,
  GET_VIEW_DATA_NO_PAG,
  GET_VIEW_NAME,
  GET_CUSTOM_EXPORT_DATA,
} from "@/core/services/store/types";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import moment from "moment";
import Papa from "papaparse";
// import JsonExcel from "vue-json-excel";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import format from '../../../assets/plugins/formvalidation/src/js/utils/format';
export default {
  name: "view_report",
  props: ["id"],
  components: {
    // downloadExcel: JsonExcel,
    // CreateFilter: () =>
    //   import(
    //     /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
    //   ),
  },
  data: function () {
    return {
      pages: 1,
      size: 20,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {},
      showFilterModal: false,
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    viewDetails(key) {
      this.$router.push({ path: `/loan/accounts/${key}` });
    },
    getColoredText() {
      const value = Object.keys(this.viewData.items[0]).includes('loan_name');
      if ( value ) return "red";
      return "black";
    },
    changePage(page = null) {
      if (page === "next") {
        this.viewData.pageIndex = this.viewData.pageIndex + 1;
        const nextPage = this.viewData.pageIndex;
        this.$store.dispatch(GET_VIEW_DATA, {
          id: this.id,
          page: nextPage,
          size: this.size,
        });
      } else if (page === "previous") {
        this.viewData.pageIndex = this.viewData.pageIndex - 1;
        const nextPage = this.viewData.pageIndex;
        this.$store.dispatch(GET_VIEW_DATA, {
          id: this.id,
          page: nextPage,
          size: this.size,
        });
      } else if (page !== null) {
        this.viewData.pageIndex = page;
        this.$store.dispatch(GET_VIEW_DATA, {
          id: this.id,
          page: page,
          size: this.size,
        });
      }
    },
    isDate(date) {
      if (date.toLowerCase() === 'creation_date') return true;
      if (date.toLowerCase() === 'last_logged_in_date') return true;
      if (date.toLowerCase() === 'customer_birth_date') return true;
      return false;
    },
    getHumanDate(date){
      return this.$options.filters.moment(date);
    },
    getViewData(page = null) {
      this.$store.dispatch(GET_VIEW_DATA, {
        id: this.id,
        page: page ? page : this.pages,
        size: this.size,
      });
      this.$store.dispatch(GET_VIEW_NAME, {
        id: this.id,
      });
    },
    getExportData() {
      this.$store.dispatch(GET_CUSTOM_EXPORT_DATA, {
        id: this.id,
        page: 1,
        size: this.viewData.totalCount,
      });
    },
    processDownload() {
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Custom View Download",
        Subject: "Custom View Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };
      wb.SheetNames.push("CustomView");
      // Set the excel sheet header
      let columns = [];
      let newArr = Object.keys(this.viewData.items[0]);
      for (let i = 0; i < newArr.length; i++) {
        columns.push(newArr[i].replace(/_/g, " ").toUpperCase());
      }
      let sheetData = [columns];
      if (
        this.customViewExport &&
        this.customViewExport.items &&
        this.customViewExport.items.length
      ) {
        for(let i=1; i<this.customViewExport.items.length; i++) {
          // Get the key for each item in the array object
          let newArr = Object.keys(this.customViewExport.items[i]);
          //Initialize a new array variable
          let arrayVariable = [];
          // Map the item key gotten earlier with for loop to its corresponding value
          for (let a = 0; a < newArr.length; a++) {
            if(newArr[a].replace(/_/g, ' ').includes('date')) {
              const dateItem = this.customViewExport.items[i][newArr[a]]
                ? moment(this.customViewExport.items[i][newArr[a]]).format("Do-MMM-YYYY")
                : "";
              arrayVariable.push(dateItem);
            } else {
              arrayVariable.push(this.customViewExport.items[i][newArr[a]]);
            }
          }
          sheetData.push(arrayVariable);
        }
      }
      let ws = XLSX.utils.aoa_to_sheet(sheetData);
      wb.Sheets["CustomView"] = ws;
      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "customView.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      let columns = [];
      let newArr = Object.keys(this.viewData.items[0]);
      for (let i = 0; i < newArr.length; i++) {
        let obj = { title: newArr[i].replace(/_/g, " ").toUpperCase(), dataKey: newArr[i] };
        columns = [...columns, obj];
      }
      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("customView.pdf");
    },
    getViewDataNoPag() {
      this.$store.dispatch(GET_VIEW_DATA_NO_PAG, this.id);
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    downloadCSVFile() {
      const jsonData = this.viewDataNoPag;
      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `views-report-${this.viewName}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setExportHeader() {
      let newArr = Object.keys(this.viewData.items[0])
      for (let i = 0; i < newArr.length; i++) {
        this.json_fields[newArr[i].replace(/_/g, " ").toUpperCase()] = newArr[i]
      }
    }
  },
  computed: {
    ...mapState({
      viewData: (state) => state.views?.viewData?.data || {},
      customViewExport: (state) => state.views?.customViewExport?.data || [],
      viewDataNoPag: (state) => state.views?.viewDataNoPag?.data || {},
      viewName: (state) => state.views?.viewName?.data?.name || null,
      loading: (state) => state.views?.loading,
      user: (state) => state.auth.user,
    }),
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },
    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      let data = [];
      if (
        this.customViewExport &&
        this.customViewExport.items &&
        this.customViewExport.items.length
      ) {
        for(let i=0; i<this.customViewExport.items.length; i++) {
          // Get the key for each item in the array object
          let newArr = Object.keys(this.customViewExport.items[i]);
          //Initialize a new object
          let newObj = {};
          // Map the item key gotten earlier with for loop to its corresponding value
          for (let a = 0; a < newArr.length; a++) {
            if(newArr[a].replace(/_/g, ' ').includes('date')) {
              newObj[newArr[a]] = this.customViewExport.items[i][newArr[a]]
                ? moment(this.customViewExport.items[i][newArr[a]]).format("Do-MMM-YYYY")
                : "";
            } else {
              newObj[newArr[a]] = this.customViewExport.items[i][newArr[a]];
            }
          }
          data.push(newObj);
        }
      }
      return data;
    },
    isLargeSizePaged() {
      return this.viewData?.totalPages > 10;
    }
  },
  watch: {
    id() {
      this.getViewData();
      this.getViewDataNoPag();
    },
    viewData: function (newVal) {
      if (newVal) {
        this.setExportHeader();
        this.getExportData();
      }
    }
  },
  mounted() {
    this.getViewData();
    this.getViewDataNoPag();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>
<style scoped>
.button--sm.button--cancel span {
  font-size: 1rem;
}
.button--cancel,
.button--grey.text--black {
  font-size: 1rem;
}
</style>